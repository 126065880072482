import React from "react";
import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";

interface Props {
  user: any
}

function Dashboard(props: Props) {
  return (
    <>
      <Container fluid>
      <Row>
          <Col md="12">
            {/* TODO - Add time based greeting, good morning etc. */}
            <h2 style={{"paddingBottom": "20px", marginTop: "0.05vh"}}>Hello {props.user.firstName}</h2>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Useful links from SolarCoin</Card.Title>
                <p className="card-category">Guides and information</p>
              </Card.Header>
              <Card.Body>
                <div className="pr-1">

                  <ul>
                    <li><a href="https://solarcoin.medium.com/migrating-to-ethereum-based-solarcoin-the-overview-740bc92d0737" target="_blank" rel="noopener noreferrer">Migrating to Ethereum-based SolarCoin: the Overview</a></li>
                    <li><a href="https://medium.com/solarcoin/migrating-to-ethereum-based-solarcoin-the-technical-guide-ad2de4dba152" target="_blank" rel="noopener noreferrer">Migrating to Ethereum-based SolarCoin: the Technical Guide</a></li>
                    <li><a href="https://solarcoin.medium.com/migrating-to-ethereum-based-solarcoin-the-faq-66d0a24b3120" target="_blank" rel="noopener noreferrer">Migrating to Ethereum-based SolarCoin: the FAQ</a></li>
                  </ul>

                </div>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">SOLARCOIN TWITTER FEED</Card.Title>
                <p className="card-category">Updates from SolarCoin</p>
              </Card.Header>
              <Card.Body>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
