/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ClaimForm from "./components/Form/ClaimForm";
import Dashboard from "./views/Dashboard";
import UserProfile from "./views/UserProfile";

interface Props {
  user: any
}

const getRoutes = (props: Props) => {
  return [
    {
      path: "/dashboard",
      name: "Home",
      icon: "nc-icon nc-explore-2",
      component: Dashboard,
      layout: "/home",
    },
    {
      path: "/claim",
      name: "File a Claim",
      icon: "nc-icon nc-single-copy-04",
      component: ClaimForm,
      layout: "/home",
    },
    {
      path: "/user",
      name: "Existing Claims",
      icon: "nc-icon nc-circle-09",
      component: UserProfile,
      layout: "/home",
    },
  ];
}


export default getRoutes;
