/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AdminLayout from "./layouts/Admin";

const config = {
  apiKey: "AIzaSyCNCsBouetGxxT3DtWbLJ2hGP-Ar30DVMA",
  authDomain: "solarcoin-claims.firebaseapp.com",
  projectId: "solarcoin-claims",
  storageBucket: "solarcoin-claims.appspot.com",
  messagingSenderId: "587495862184",
  appId: "1:587495862184:web:d64499f1489506e3deb79d",
  measurementId: "G-HC6804MY6P"
};

export const app = firebase.initializeApp(config);

export const db = app.firestore;


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/home" render={(props) => <AdminLayout {...props} />} />
      <Redirect from="/" to="/home/dashboard" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
