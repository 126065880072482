// Import FirebaseAuth and firebase.
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import styled from 'styled-components'
import { Claim } from '../../types';

interface Props {
  updateUserInfo: (user: any) => void,
  updateClaimsInfo: (claims: Claim[]) => void
}

const LoginScreen = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffc201;
`

const LeftPanel = styled.div`
display: flex;
flex: 0 1 100%;
flex-wrap: wrap;
padding: 4rem;
`

const RightPanel = styled.div`
display: flex;
flex: 0 1 100%;
justify-content: center;
flex-wrap: wrap;
`

const LoginDescription = styled.div`
  
`
const LoginDescriptionHeader = styled.div`
  display: flex;
  flex: 0 1 100%;
  font-size: 2rem;
  font-weight: 500;
`
const LoginTitle = styled.div`
  display: flex;
  flex: 0 1 100%;
  font-size: 1.5rem;
  font-weight: 500;
  justify-content: center;
`

const UnderlinedLink = styled.a`
  font-weight: 500;
  text-decoration: underline;
  color: #000;
`

// export const auth = app.auth();

function SignInScreen(props: Props) {

  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      // enable more sign in options here after updating in firebase console
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (res: any) => {
        const user = res.user as firebase.User;
        const getClaim = firebase.functions().httpsCallable('retrieveFacilities')
        getClaim().then((claimResponse => {
          const completeUser = {...user, ...claimResponse.data[0]};
          
          props.updateUserInfo(completeUser);
          props.updateClaimsInfo(claimResponse.data)
        }));

        res.getSUer()

        return true;
      }
    }
  };


 
  // auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)

  return (
    <LoginScreen>
      <LeftPanel>
        <LoginDescriptionHeader>
          <span>Claim your SolarCoin.</span>
        </LoginDescriptionHeader>
        <LoginDescription>
          <p><UnderlinedLink href="https://solarcoin.org">SolarCoin</UnderlinedLink> is a cryptocurrency that incentivizes a solar-powered planet. </p>
            <p>We distribute SolarCoin as a reward to solar installations. When the value and price of a SolarCoin exceed the production cost of the energy, it becomes effectively free – an event we call the Solarity. Solar energy is now the cheapest fuel in over 150 countries, and we want to make it free.</p>
        </LoginDescription>
        <LoginDescription>
          <p>If you generate solar energy, you are eligible to claim your SolarCoin at no cost - we just need some information about your solar installation.</p>
        </LoginDescription>
            
      </LeftPanel>
      <RightPanel>
        <LoginTitle>Login or sign up</LoginTitle>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </RightPanel>
        
            
    </LoginScreen>
  );
}

export default SignInScreen