import firebase from "firebase";
import React, { useEffect } from "react";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";

interface Props {
  user: any,
  claims: any[],
  updateClaims: () => void
}

function User(props: Props) {

  useEffect(() => {
    const claimWallets =props.claims ? 
    props.claims.reduce((walletsobj, claim) => {
      return {
        ...walletsobj,
        [claim.claimId]: claim.walletAddress,
      };
    }, {})
    :
    {}
    setWalletsByClaim(claimWallets)
  }, [props.claims])
  const [walletsByClaim, setWalletsByClaim] = React.useState({} as {[key: string]: string})

  const updateWalletAddress = (claimId: string) => {
    const updateWallet = firebase.functions().httpsCallable('setWalletAddress');
    updateWallet({
      claimId: claimId, 
      walletAddress: walletsByClaim[claimId]
    }).then(() => {
      props.updateClaims();
    }).catch((err) => console.log(err))
  }

  const handleWalletFieldChange = (claimId: string) => {
    return function (e: any) {
      setWalletsByClaim({...walletsByClaim,[claimId]: e.target.value})
    }
  }

  if (props.claims == null || props.claims === []) {
    props.updateClaims();
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Personal Details</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form >
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Form.Control
                          placeholder="Email"
                          type="email"
                          value={props.user.email}
                          // TODO- enable when updating is possible
                          disabled
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>First Name</label>
                        <Form.Control
                          defaultValue=""
                          placeholder="First Name"
                          type="text"
                          value={props.user.firstName}
                          disabled
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>Last Name</label>
                        <Form.Control
                          defaultValue=""
                          placeholder="Last Name"
                          type="text"
                          value={props.user.lastName}
                          disabled
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>




            {props.claims == null || props.claims === [] ? <></> : 

            props.claims.map((claim) => {
              return (
                <React.Fragment key={claim.claimId}>
                <Col md="12">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Claim {props.claims.indexOf(claim) + 1} - {claim.address1}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                      <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Claim ID</label>
                              <Form.Control
                                defaultValue=""
                                placeholder="Claim ID"
                                type="text"
                                value={claim.claimId}
                                disabled
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Address</label>
                              <Form.Control
                                defaultValue=""
                                placeholder="Address"
                                type="text"
                                value={claim.address1}
                                disabled
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="4">
                            <Form.Group>
                              <label>City</label>
                              <Form.Control
                                defaultValue=""
                                placeholder="City"
                                type="text"
                                value={claim.city}
                                disabled
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="px-1" md="4">
                            <Form.Group>
                              <label>Country</label>
                              <Form.Control
                                defaultValue=""
                                placeholder="Country"
                                type="text"
                                value={claim.country}
                                disabled
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="4">
                            <Form.Group>
                              <label>Zip Code</label>
                              <Form.Control
                                placeholder="ZIP Code"
                                type="text"
                                value={claim.zipCode}
                                disabled
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>Nameplate capacity</label>
                              <Form.Control
                                defaultValue=""
                                placeholder="Nameplate"
                                type="text"
                                value={claim.nameplate}
                                disabled
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="6">
                            <Form.Group>
                              <label>Interconnection Date</label>
                              <Form.Control
                                defaultValue=""
                                placeholder="Interconnection Date"
                                type="text"
                                value={claim.installDate}
                                disabled
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>Monitoring website link</label>
                              <Form.Control
                                placeholder="Monitoring website link"
                                type="text"
                                value={claim.monitoringLink}
                                disabled
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="6">
                            <Form.Group>
                              <label htmlFor="exampleInputEmail1">
                                Energy Web Chain wallet address (starts with 0x...)
                              </label>
                              <Form.Control
                                placeholder="Wallet Address"
                                type="text"
                                value={walletsByClaim[claim.claimId] ? walletsByClaim[claim.claimId]: ""}
                                onChange={handleWalletFieldChange(claim.claimId)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                    <Button variant="primary" onClick={() => updateWalletAddress(claim.claimId)}>
                      Update wallet address
                    </Button>
                    </Col>
                        </Row>
                      <div className="clearfix"></div>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </React.Fragment>
            )
          })}
        </Row>
      </Container>
    </>
  );
}

export default User;
