/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import firebase from 'firebase';

import AdminNavbar from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import getRoutes from "../routes";
import sidebarImage from "../assets/img/sidebar-3.jpg"
import SignInScreen from "../components/Auth/SignIn";
import { LoadingPlaceholder } from "../components/LoadingPlaceholder";

import { Claim } from "../types";

function Admin(props: any) {
  const image = sidebarImage
  const color = "black"
  const location = useLocation();
  const mainPanel = React.useRef(null);

  const [user, updateUserInfo] = React.useState(null as unknown as Claim);
  const [loading, updateLoadingState] = React.useState(true);
  const [claims, updateClaimsInfo] = React.useState(null as unknown as Claim[]);

  const updateClaims = () => {
    const getClaim = firebase.functions().httpsCallable('retrieveFacilities')
    getClaim().then((claimResponse => {
      const completeUser = {...user, ...claimResponse.data[0]};
      
      updateUserInfo(completeUser);
      updateClaimsInfo(claimResponse.data)
    }));
  }

  if (user == null) {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {    
        const name = user.displayName? user.displayName!.split(' ') : ["",""];
        const firstName = name[0] ? name[0] : "";
        const lastName = name[1] ? name[1] : "";
    
        const userState = {
          firstName,
          lastName,
          email: user.email,
        }
      
        updateUserInfo(userState as unknown as Claim)

        updateLoadingState(false);
      } else {
        updateLoadingState(false);
      }
    });
  }

  const setUserInfo = (user: firebase.User) => {
    if (user == null) {
      updateUserInfo(user as unknown as Claim)
    }
    else {
      const name = user.displayName? user.displayName!.split(' ') : ["",""];
      const firstName = name[0] ? name[0] : "";
      const lastName = name[1] ? name[1] : "";
  
      const userState = {
        ...user,
        firstName,
        lastName,
        email: user.email,
      }
  
      updateUserInfo(userState as unknown as Claim)
    }
  }

  const routes = getRoutes({user});

  const getAdminRoutes = (routes: any, user: any, claims: any[], updateClaims: () => void) => {
    return routes.map((prop: any, key: any) => {
      if (prop.layout === "/home") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => {
              const propsWithUser = {...props, user, claims, updateClaims}
              return <prop.component {...propsWithUser} />}
            }
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement!.scrollTop = 0;

    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element!.parentNode!.removeChild(element!);
    }
  }, [location, user]);

  if (user == null) {
    const signedInUser = props.user;

    if (signedInUser != null) {
      setUserInfo(signedInUser as any);
    }
  }
  
  return (
    <>
    {loading === true ? <LoadingPlaceholder /> :
        user == null && loading === false ? <><SignInScreen {...{updateUserInfo: setUserInfo, updateClaimsInfo}} /></> : <> <div className="wrapper">
        <Sidebar color={color} image={image ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar {...{user, setUserInfo}} />
          <div className="content">
            <Switch>{getAdminRoutes(routes, user, claims, updateClaims)}</Switch>
          </div>
        </div>
      </div>
      </>
    }
    </>  
  );
}

export default Admin;
