export const CountryMap = new Map<string, string>();

CountryMap.set('Afghanistan', 'AF');
CountryMap.set('Albania', 'AL');
CountryMap.set('Algeria', 'DZ');
CountryMap.set('American Samoa', 'AS');
CountryMap.set('Andorra', 'AD');
CountryMap.set('Angola', 'AO');
CountryMap.set('Anguilla', 'AI');
CountryMap.set('Antarctica', 'AQ');
CountryMap.set('Antigua and Barbuda', 'AG');
CountryMap.set('Argentina', 'AR');
CountryMap.set('Armenia', 'AM');
CountryMap.set('Armenia', 'AM');
CountryMap.set('Aruba', 'AW');
CountryMap.set('Australia', 'AU');
CountryMap.set('Austria', 'AT');
CountryMap.set('Azerbaijan', 'AZ');
CountryMap.set('Bahamas (the)', 'BS');
CountryMap.set('Bahrain', 'BH');
CountryMap.set('Bangladesh', 'BD');
CountryMap.set('Barbados', 'BB');
CountryMap.set('Belarus', 'BY');
CountryMap.set('Belgium', 'BE');
CountryMap.set('Belize', 'BZ');
CountryMap.set('Benin', 'BJ');
CountryMap.set('Bermuda', 'BM');
CountryMap.set('Bhutan', 'BT');
CountryMap.set('Bolivia (Plurinational State of)', 'BO');
CountryMap.set('Bonaire, Sint Eustatius and Saba', 'BQ');
CountryMap.set('Bosnia and Herzegovina', 'BA');
CountryMap.set('Botswana', 'BW');
CountryMap.set('Bouvet Island', 'BV');
CountryMap.set('Brazil', 'BR');
CountryMap.set('British Indian Ocean Territory (the)', 'IO');
CountryMap.set('Brunei Darussalam', 'BN');
CountryMap.set('Bulgaria', 'BG');
CountryMap.set('Burkina Faso', 'BF');
CountryMap.set('Burundi', 'BI');
CountryMap.set('Cabo Verde', 'CV');
CountryMap.set('Cambodia', 'KH');
CountryMap.set('Cameroon', 'CM');
CountryMap.set('Canada', 'CA');
CountryMap.set('Cayman Islands (the)', 'KY');
CountryMap.set('Central African Republic (the)', 'CF');
CountryMap.set('Chad', 'TD');
CountryMap.set('Chile', 'CL');
CountryMap.set('China', 'CN');
CountryMap.set('Christmas Island', 'CX');
CountryMap.set('Cocos (Keeling) Islands (the)', 'CC');
CountryMap.set('Colombia', 'CO');
CountryMap.set('Comoros (the)', 'KM');
CountryMap.set('Congo (the Democratic Republic of the)', 'CD');
CountryMap.set('Congo (the)', 'CG');
CountryMap.set('Cook Islands (the)', 'CK');
CountryMap.set('Costa Rica', 'CR');
CountryMap.set('Croatia', 'HR');
CountryMap.set('Cuba', 'CU');
CountryMap.set('Curaçao', 'CW');
CountryMap.set('Cyprus', 'CY');
CountryMap.set('Czechia', 'CZ');
CountryMap.set("Côte d'Ivoire", 'CI');
CountryMap.set('Denmark', 'DK');
CountryMap.set('Djibouti', 'DJ');
CountryMap.set('Dominica', 'DM');
CountryMap.set('Dominican Republic (the)', 'DO');
CountryMap.set('Ecuador', 'EC');
CountryMap.set('Egypt', 'EG');
CountryMap.set('El Salvador', 'SV');
CountryMap.set('Equatorial Guinea', 'GQ');
CountryMap.set('Eritrea', 'ER');
CountryMap.set('Estonia', 'EE');
CountryMap.set('Eswatini', 'SZ');
CountryMap.set('Ethiopia', 'ET');
CountryMap.set('Falkland Islands (the) [Malvinas]', 'FK');
CountryMap.set('Faroe Islands (the)', 'FO');
CountryMap.set('Fiji', 'FJ');
CountryMap.set('Finland', 'FI');
CountryMap.set('France', 'FR');
CountryMap.set('French Guiana', 'GF');
CountryMap.set('French Polynesia', 'PF');
CountryMap.set('French Southern Territories (the)', 'TF');
CountryMap.set('Gabon', 'GA');
CountryMap.set('Gambia (the)', 'GM');
CountryMap.set('Georgia', 'GE');
CountryMap.set('Germany', 'DE');
CountryMap.set('Ghana', 'GH');
CountryMap.set('Gibraltar', 'GI');
CountryMap.set('Greece', 'GR');
CountryMap.set('Greenland', 'GL');
CountryMap.set('Grenada', 'GD');
CountryMap.set('Guadeloupe', 'GP');
CountryMap.set('Guam', 'GU');
CountryMap.set('Guatemala', 'GT');
CountryMap.set('Guernsey', 'GG');
CountryMap.set('Guinea', 'GN');
CountryMap.set('Guinea-Bissau', 'GW');
CountryMap.set('Guyana', 'GY');
CountryMap.set('Haiti', 'HT');
CountryMap.set('Heard Island and McDonald Islands', 'HM');
CountryMap.set('Holy See (the)', 'VA');
CountryMap.set('Honduras', 'HN');
CountryMap.set('Hong Kong', 'HK');
CountryMap.set('Hungary', 'HU');
CountryMap.set('Iceland', 'IS');
CountryMap.set('India', 'IN');
CountryMap.set('Indonesia', 'ID');
CountryMap.set('Iran (Islamic Republic of)', 'IR');
CountryMap.set('Iraq', 'IQ');
CountryMap.set('Ireland', 'IE');
CountryMap.set('Isle of Man', 'IM');
CountryMap.set('Israel', 'IL');
CountryMap.set('Italy', 'IT');
CountryMap.set('Jamaica', 'JM');
CountryMap.set('Japan', 'JP');
CountryMap.set('Jersey', 'JE');
CountryMap.set('Jordan', 'JO');
CountryMap.set('Kazakhstan', 'KZ');
CountryMap.set('Kenya', 'KE');
CountryMap.set('Kiribati', 'KI');
CountryMap.set("Korea (the Democratic People's Republic of)", 'KP');
CountryMap.set('Korea (the Republic of)', 'KR');
CountryMap.set('Kuwait', 'KW');
CountryMap.set('Kyrgyzstan', 'KG');
CountryMap.set("Lao People's Democratic Republic (the)", 'LA');
CountryMap.set('Latvia', 'LV');
CountryMap.set('Lebanon', 'LB');
CountryMap.set('Lesotho', 'LS');
CountryMap.set('Liberia', 'LR');
CountryMap.set('Libya', 'LY');
CountryMap.set('Liechtenstein', 'LI');
CountryMap.set('Lithuania', 'LT');
CountryMap.set('Luxembourg', 'LU');
CountryMap.set('Macao', 'MO');
CountryMap.set('Madagascar', 'MG');
CountryMap.set('Malawi', 'MW');
CountryMap.set('Malaysia', 'MY');
CountryMap.set('Maldives', 'MV');
CountryMap.set('Mali', 'ML');
CountryMap.set('Malta', 'MT');
CountryMap.set('Marshall Islands (the)', 'MH');
CountryMap.set('Martinique', 'MQ');
CountryMap.set('Mauritania', 'MR');
CountryMap.set('Mauritius', 'MU');
CountryMap.set('Mayotte', 'YT');
CountryMap.set('Mexico', 'MX');
CountryMap.set('Micronesia (Federated States of)', 'FM');
CountryMap.set('Moldova (the Republic of)', 'MD');
CountryMap.set('Monaco', 'MC');
CountryMap.set('Mongolia', 'MN');
CountryMap.set('Montenegro', 'ME');
CountryMap.set('Montserrat', 'MS');
CountryMap.set('Morocco', 'MA');
CountryMap.set('Mozambique', 'MZ');
CountryMap.set('Myanmar', 'MM');
CountryMap.set('Namibia', 'NA');
CountryMap.set('Nauru', 'NR');
CountryMap.set('Nepal', 'NP');
CountryMap.set('Netherlands (the)', 'NL');
CountryMap.set('New Caledonia', 'NC');
CountryMap.set('New Zealand', 'NZ');
CountryMap.set('Nicaragua', 'NI');
CountryMap.set('Niger (the)', 'NE');
CountryMap.set('Nigeria', 'NG');
CountryMap.set('Niue', 'NU');
CountryMap.set('Norfolk Island', 'NF');
CountryMap.set('Northern Mariana Islands (the)', 'MP');
CountryMap.set('Norway', 'NO');
CountryMap.set('Oman', 'OM');
CountryMap.set('Pakistan', 'PK');
CountryMap.set('Palau', 'PW');
CountryMap.set('Palestine, State of', 'Ps');
CountryMap.set('Panama', 'PA');
CountryMap.set('Papua New Guinea', 'PG');
CountryMap.set('Paraguay', 'PY');
CountryMap.set('Peru', 'PE');
CountryMap.set('Philippines (the)', 'PH');
CountryMap.set('Pitcairn', 'PN');
CountryMap.set('Poland', 'PL');
CountryMap.set('Portugal', 'PT');
CountryMap.set('Puerto Rico', 'PR');
CountryMap.set('Qatar', 'QA');
CountryMap.set('Republic of North Macedonia', 'MK');
CountryMap.set('Romania', 'RO');
CountryMap.set('Russian Federation (the)', 'RU');
CountryMap.set('Rwanda', 'RW');
CountryMap.set('Réunion', 'RE');
CountryMap.set('Saint Barthélemy', 'BL');
CountryMap.set('Saint Helena, Ascension and Tristan da Cunha', 'SH');
CountryMap.set('Saint Kitts and Nevis', 'KN');
CountryMap.set('Saint Lucia', 'LC');
CountryMap.set('Saint Martin (French part)', 'MF');
CountryMap.set('Saint Pierre and Miquelon', 'PM');
CountryMap.set('Saint Vincent and the Grenadines', 'VC');
CountryMap.set('Samoa', 'WS');
CountryMap.set('San Marino', 'SM');
CountryMap.set('Sao Tome and Principe', 'ST');
CountryMap.set('Saudi Arabia', 'SA');
CountryMap.set('Senegal', 'SN');
CountryMap.set('Serbia', 'RS');
CountryMap.set('Seychelles', 'SC');
CountryMap.set('Sierra Leone', 'SL');
CountryMap.set('Singapore', 'SG');
CountryMap.set('Sint Maarten (Dutch part)', 'SX');
CountryMap.set('Slovakia', 'SK');
CountryMap.set('Slovenia', 'SI');
CountryMap.set('Solomon Islands', 'SB');
CountryMap.set('Somalia', 'SO');
CountryMap.set('South Africa', 'ZA');
CountryMap.set('South Georgia and the South Sandwich Islands', 'GS');
CountryMap.set('South Sudan', 'SS');
CountryMap.set('Spain', 'ES');
CountryMap.set('Sri Lanka', 'LK');
CountryMap.set('Sudan (the)', 'SD');
CountryMap.set('Suriname', 'SR');
CountryMap.set('Svalbard and Jan Mayen', 'SJ');
CountryMap.set('Sweden', 'SE');
CountryMap.set('Switzerland', 'CH');
CountryMap.set('Syrian Arab Republic', 'SY');
CountryMap.set('Taiwan (Province of China)', 'TW');
CountryMap.set('Tajikistan', 'TJ');
CountryMap.set('Tanzania, United Republic of', 'TZ');
CountryMap.set('Thailand', 'TH');
CountryMap.set('Timor-Leste', 'TL');
CountryMap.set('Togo', 'TG');
CountryMap.set('Tokelau', 'TK');
CountryMap.set('Tonga', 'TO');
CountryMap.set('Trinidad and Tobago', 'TT');
CountryMap.set('Tunisia', 'TN');
CountryMap.set('Turkey', 'TR');
CountryMap.set('Turkmenistan', 'TM');
CountryMap.set('Turks and Caicos Islands (the)', 'TC');
CountryMap.set('Tuvalu', 'TV');
CountryMap.set('Uganda', 'UG');
CountryMap.set('Ukraine', 'UA');
CountryMap.set('United Arab Emirates (the)', 'AE');
CountryMap.set('United Kingdom of Great Britain and Northern Ireland (the)', 'GB');
CountryMap.set('United States Minor Outlying Islands (the)', 'UM');
CountryMap.set('United States of America (the)', 'US');
CountryMap.set('Uruguay', 'UY');
CountryMap.set('Uzbekistan', 'UZ');
CountryMap.set('Vanuatu', 'VU');
CountryMap.set('Venezuela (Bolivarian Republic of)', 'VE');
CountryMap.set('Vietnam', 'VN');
CountryMap.set('Virgin Islands (British)', 'VG');
CountryMap.set('Virgin Islands (U.S.)', 'VI');
CountryMap.set('Wallis and Futuna', 'WF');
CountryMap.set('Western Sahara', 'EH');
CountryMap.set('Yemen', 'YE');
CountryMap.set('Zambia', 'ZM');
CountryMap.set('Zimbabwe', 'ZW');
CountryMap.set('Åland Islands', 'AX');

export const countryList = CountryMap.keys();
