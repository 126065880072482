import firebase from "firebase";
import React from "react";
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import { Button, Container, Form, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from 'moment'

import {countryList, CountryMap} from './CountryMap';

import { Claim } from "../../types";

interface Props {
  claim: Claim;
  updateClaims: () => void
}

interface State {
  value: any;
  claim: Claim;
  submitted: boolean;
  fileName: string
  file?: File
}

const countryDropDown = Array.from(countryList).map((countryName) => <option>{countryName}</option>)

class ClaimForm extends React.Component<Props, State> {
  private validator: SimpleReactValidator;

  constructor(props: any) {
    super(props);
    this.state = {value: '', claim: {} as Claim, submitted: false, fileName: ''};
    this.validator = new SimpleReactValidator({
      validators: {
        before: { message: `Date must be before ${moment().toISOString()}`, rule: (val: any) => val.isBefore() }
      }
    });

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event: any, key: string) {

    if(key === 'country') {
      this.setState({claim: {
        ...this.state.claim,
        country: CountryMap.get(event.target.value) as string,
      }});
    }
    else {
      this.setState({claim: {
        ...this.state.claim,
        [key]: event.target.value,
      }});
    }
  }

  handleFileChange(e: any) {
    this.setState({
      fileName: e.target.files[0].name,
      file: e.target.files[0]
    })
  }

  handleSubmit(event: any) {
    const form = event.currentTarget;

    this.setState({
      ...this.state,
      claim: {
        ...this.state.claim,
        installDate: moment(this.state.claim.installDate, "YYYY-MM-DD").toISOString(),
        docs: form.file,
      }
    });

    const hasNeitherDocsOrMonitorLink = this.state.claim.monitoringLink == null && this.state.fileName === '';

    if (this.validator.allValid() === false || hasNeitherDocsOrMonitorLink) {
      event.preventDefault();
      event.stopPropagation();
      this.validator.showMessages();
      this.forceUpdate();
    }

    else {
      const functions = firebase.functions()
  
      const addClaim = functions.httpsCallable('addFacility')
      addClaim({
        email: this.state.claim.email,
        firstName: this.state.claim.firstName,
        lastName: this.state.claim.lastName,
        address1: this.state.claim.address,
        address2: this.state.claim.address2,
        city: this.state.claim.city,
        state: this.state.claim.state,
        zipCode: this.state.claim.zipCode,
        country: this.state.claim.country,
        nameplate: this.state.claim.nameplate,
        installDate: this.state.claim.installDate,
        documentation: this.state.claim.documentation,
        monitoringLink: this.state.claim.monitoringLink,
        walletAddress: this.state.claim.walletAddress,
        hasSignedRelease: this.state.claim.hasSignedRelease,
        hasAgreedToTerms: this.state.claim.hasAgreedToTerms,
        facilityId: this.state.claim.facilityId,
        inverterManufacturer: this.state.claim.inverterManufacturer,
        inverterManufacturerOther: this.state.claim.inverterManufacturerOther,
        monitoringPlatform: this.state.claim.monitoringPlatform,
        monitoringPlatformOther: this.state.claim.monitoringPlatformOther,
        docs: this.state.claim.docs,
        docsFileName: this.state.fileName
      })
      .then((result) => {
        
        // If a result is present it's the signed URL for a file upload
        if(result && result.data && this.state.file) {
          axios.put(result.data[0], this.state.file)
        }

        this.props.updateClaims();
      })
      .catch((error) => {
        console.log(error)
      })

      this.setState({
        ...this.state,
        submitted: true,
      })
      event.preventDefault();
    }
    
  }

  render() {
    return (<>
    {this.state.submitted ? <>
         <Container fluid>
           <div>
             <p>Claim submitted successfully! We're updating our systems to process claims faster - for now, it will take a couple of weeks. We will notify you by email when your claim has been processed. Welcome to the SolarCoin community!</p>
             <Link to="/home/dashboard" className="btn btn-primary">Back to dashboard</Link>
           </div>
         </Container>
      </> :
      <>
       <Container fluid>
        <Form noValidate={true} onSubmit={this.handleSubmit}>
  
          <Form.Row>
            <Form.Group as={Col} controlId="formGridFirstName">
              <Form.Label>First name</Form.Label>
              <Form.Control type="text" placeholder="First name" onChange={(event) => this.handleChange(event, 'firstName')} />
              <div style={{color: 'red'}}>{this.validator.message('firstName', this.state.claim.firstName, 'required')}</div>
            </Form.Group>
  
            <Form.Group as={Col} controlId="formGridLastName">
              <Form.Label>Last name</Form.Label>
              <Form.Control type="text" placeholder="Last name" onChange={(event) => this.handleChange(event, 'lastName')} />
              <div style={{color: 'red'}}> {this.validator.message('lastName', this.state.claim.lastName, 'required')}</div>
            </Form.Group>
  
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Email" onChange={(event) => this.handleChange(event, 'email')} />
              <div style={{color: 'red'}}>{this.validator.message('email', this.state.claim.email, 'required')}</div>
            </Form.Group>
          </Form.Row>
  
          <Form.Row>
            <Form.Group as={Col} controlId="formGridAddress1">
              <Form.Label>Address line 1</Form.Label>
              <Form.Control placeholder="1234 Main St" onChange={(event) => this.handleChange(event, 'address')}/>
              <div style={{color: 'red'}}>{this.validator.message('address', this.state.claim.address, 'required')}</div>
              <Form.Text className="text-muted">
                Address of solar facility location.
              </Form.Text>
            </Form.Group>
  
            <Form.Group as={Col} controlId="formGridAddress2">
              <Form.Label>Address line 2 (optional)</Form.Label>
              <Form.Control placeholder="Apartment, studio, or floor" onChange={(event) => this.handleChange(event, 'address2')} />
              <Form.Text className="text-muted">
                Address of solar facility location.
              </Form.Text>
            </Form.Group>
  
          </Form.Row>
  
          <Form.Row>
  
          <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>City</Form.Label>
              <Form.Control placeholder="City" onChange={(event) => this.handleChange(event, 'city')} />
              <div style={{color: 'red'}}>{this.validator.message('city', this.state.claim.city, 'required')}</div>
            </Form.Group>
  
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>State/Province</Form.Label>
              <Form.Control placeholder="State or province" onChange={(event) => this.handleChange(event, 'state')} />
              <div style={{color: 'red'}}>{this.validator.message('state', this.state.claim.state, 'required')}</div>
            </Form.Group>
  
            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Post Code</Form.Label>
              <Form.Control placeholder="Zip code" onChange={(event) => this.handleChange(event, 'zipCode')} />
              <div style={{color: 'red'}}>{this.validator.message('postCode', this.state.claim.zipCode, 'required')}</div>
            </Form.Group>
  
            <Form.Group as={Col} controlId="formCountry">
              <Form.Label>Country</Form.Label>
              <Form.Control as="select" onChange={(event) => this.handleChange(event, 'country')}>
                {countryDropDown}
              </Form.Control>
              <div style={{color: 'red'}}>{this.validator.message('country', this.state.claim.country, 'required')}</div>
            </Form.Group>
  
          </Form.Row>
  
          <Form.Row>
  
          <Form.Group as={Col} controlId="formNameplate">
              <Form.Label>Nameplate capacity (kW)</Form.Label>
              <Form.Control placeholder="Facility nameplate capacity in kW" onChange={(event) => this.handleChange(event, 'nameplate')} />
              <div style={{color: 'red'}}>{this.validator.message('nameplate', this.state.claim.nameplate, 'required|min:.01|max:50000')}</div>
              <Form.Text className="text-muted">
                Please enter a value between 0.01 and 50000 kW. Most home installations are between 4 and 8 kW.
              </Form.Text>
            </Form.Group>
            
            {/* <Form.Group as={Col} controlId="formFacilityId">
              <Form.Label>Location type</Form.Label>
              <Form.Control placeholder="Location type" required onChange={(event) => this.handleChange(event, 'facilityId')} />
              <Form.Text className="text-muted">
                Used to identify your facility. 
              </Form.Text>
            </Form.Group> */}
  
            <Form.Group as={Col} controlId="formFacilityConnectionDate">
              <Form.Label>Interconnection date</Form.Label>
              <Form.Control type="date" onChange={(event) => this.handleChange(event, 'installDate')} />
              <div style={{color: 'red'}}>{this.validator.message('installDate', moment(this.state.claim.installDate), 'required|before' )}</div>
              {/* <div style={{color: 'red'}}>{this.validator.message('installDate', moment(this.state.claim.installDate), ['required'])}</div> */}
              <Form.Text className="text-muted">
                Date the solar facility was connected to the utility or became operational. SolarCoin are granted retroactively for a maximum of 5 years. 
              </Form.Text>
            </Form.Group>
  
          </Form.Row>
  
          {/* <Form.Row>
  
          <Form.Group as={Col} controlId="formInverterManufacturer">
  
            <Form.Label>Inverter Manufacturer</Form.Label>
              <Form.Control as="select" onChange={(event) => this.handleChange(event, 'inverterManufacturer')}>
                <option>Manufacturer One</option>
                <option>Manufacturer Two</option>
                <option>Manufacturer Three</option>
              </Form.Control>
              <Form.Text className="text-muted">
                Optional.
              </Form.Text>
            </Form.Group>
  
            <Form.Group as={Col} controlId="formInverterManufacturerOther">
            <Form.Label>Inverter Manufacturer Other</Form.Label>
              <Form.Control placeholder="Inverter Manufacturer Other" as="textarea" rows={1} onChange={(event) => this.handleChange(event, 'inverterManufacturerOther')} />
              <Form.Text className="text-muted">
                Optional.
              </Form.Text>
            </Form.Group>
  
  
          </Form.Row> */}
  
          <Form.Row>
  
          <Form.Group as={Col} controlId="formMonitoringPlatform">
              <Form.Label>Solar Monitoring Platform (optional)</Form.Label>
              <Form.Control as="select" onChange={(event) => this.handleChange(event, 'monitoringPlatform')}>
                <option>3Megawatt</option>
                <option>ABB</option>
                <option>Accuenergy</option>
                <option>AES</option>
                <option>AGL</option>
                <option>AlsoEnergy (Skytron)</option>
                <option>Autarco</option>
                <option>Chilicon Power</option>
                <option>Curb Monitoring</option>
                <option>Delta</option>
                <option>E.ON</option>
                <option>eGauge systems</option>
                <option>Empuron</option>
                <option>Enphase</option>
                <option>Fimer</option>
                <option>Fronius</option>
                <option>Ginlong</option>
                <option>Goodwe</option>
                <option>GreenByte</option>
                <option>GreenPowerMonitor</option>
                <option>Growatt</option>
                <option>Heliolytics</option>
                <option>Huawei</option>
                <option>Inaccess</option>
                <option>Ingeteam</option>
                <option>Kaco</option>
                <option>Kostal</option>
                <option>Mastervolt</option>
                <option>MeteoControl</option>
                <option>Morningstar Corp</option>
                <option>Nextracker</option>
                <option>Outback Power</option>
                <option>OWL</option>
                <option>PG+E</option>
                <option>Power Electronics</option>
                <option>QOS Energy</option>
                <option>Scada</option>
                <option>Schneider</option>
                <option>Sense Energy Monitor</option>
                <option>SMA</option>
                <option>Smappee</option>
                <option>Sol-Ark</option>
                <option>Solar Analytics</option>
                <option>SolarEdge</option>
                <option>Solar-Log</option>
                <option>SolarMax</option>
                <option>SolarPower</option>
                <option>SolarView</option>
                <option>Solarwatt</option>
                <option>SolarWeb</option>
                <option>SunGrow</option>
                <option>SunPower</option>
                <option>SunPro</option>
                <option>SunSniffer</option>
                <option>SunWays</option>
                <option>Tecsol</option>
                <option>TED</option>
                <option>Tesla</option>
                <option>Tigo Energy</option>
                <option>TMEIC</option>
                <option>Viessmann</option>
              </Form.Control>
              <Form.Text className="text-muted">
                Where you go to see your energy production data. May be a utility, inverter, or other platform.
              </Form.Text>
            </Form.Group>
  
            <Form.Group as={Col} controlId="monitoringPlatformOther">
              <Form.Label>Solar Monitoring Platform, if not listed (optional)</Form.Label>
              <Form.Control placeholder="Monitoring Platform Other" as="textarea" rows={1} onChange={(event) => this.handleChange(event, 'monitoringPlatformOther')} />
              <Form.Text className="text-muted">
                Optional.
              </Form.Text>
            </Form.Group>
  
          </Form.Row>

        <Form.Row>
        
        <Form.Group as={Col} controlId="formDocs">
        <h4>Documentation</h4>
        <p>Please upload a file and/or provide a publicly accessible website link.</p> 
        <p>Note that installations greater than 100 kW nameplate capacity require both.</p>
          <Form.Label>Facility documentation</Form.Label>
          <Form.File 
            id="docs-file"
            label={this.state.fileName ? this.state.fileName : "Browse for file.."}
            onChange={(e: any) => this.handleFileChange(e)}
            custom
          />
          <Form.Text className="text-muted">
            Must show address, nameplate capacity, installation date, and the claimant's name. 
            One file only.
            5 MB limit.
            Allowed types: jpg jpeg gif png pdf
          </Form.Text>
          </Form.Group>
        </Form.Row>

        <Form.Row>
        <Form.Group as={Col} controlId="formMonitoringLink">
            <Form.Label>Link to website of the solar facility monitoring software</Form.Label>
            <Form.Control type="text" placeholder="Link to monitoring portal" onChange={(event) => this.handleChange(event, 'monitoringLink')} />
            <Form.Text className="text-muted">
              Must show address, nameplate capacity, installation date, and the claimant's name. 
            </Form.Text>
          </Form.Group>
        </ Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="formWalletAddress">
            <Form.Label>SolarCoin Public Wallet Address</Form.Label>
            <Form.Control placeholder="Wallet address" onChange={(event) => this.handleChange(event, 'walletAddress')} />
            <div style={{color: 'red'}}>{this.validator.message('walletAddress', this.state.claim.walletAddress, 'required|size:42')}</div>
            <Form.Text className="text-muted">
              An address on the <a href="https://www.energyweb.org">Energy Web Chain</a> to receive SolarCoin. Should be a 42-character string starting with '0x'
            </Form.Text>
            </Form.Group>
          </Form.Row>
  
          <Form.Row>
  
          <Form.Group id="formGridRelease">
            <Form.Check type="checkbox" label="Release confirmation (optional)" onChange={(event) => this.handleChange(event, 'hasSignedRelease')} style={{opacity: "initial", paddingLeft: "30px"}}/>
            <Form.Text className="text-muted" style={{paddingLeft: "30px"}}>
              I would like to help the SolarCoin community by allowing this information to be used in press releases or other communications.
            </Form.Text>
          </Form.Group>
          </Form.Row>
  
          <Form.Row>
          <Form.Group id="formGridCookie">    
          <Form.Check 
            label="I have read and agree to the Cookie and Privacy Policy" onChange={(event) => this.handleChange(event, 'hasAgreedToTerms')} style={{opacity: "initial", paddingLeft: "30px"}} />
            <div style={{color: 'red'}}>{this.validator.message('hasAgreedToTerms', this.state.claim.hasAgreedToTerms, 'required')}</div>
          </Form.Group>
          </Form.Row>
  
          <Button variant="primary" type="submit" value="Submit">
            Submit
          </Button>
        </Form>
      </Container>
      </>
      }
    </>)
  }
}

export default ClaimForm;
